var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card__center"},[(_vm.$store.state.isLinkedFooterLoading)?_c('div',[_c('i',{staticClass:"fa fa-circle-o-notch fa-spin loader"}),_vm._v(" Loading... ")]):_c('div',{staticClass:"card__center"},[_c('Divider'),(!_vm.selectedAddress)?_c('div',{staticClass:"btn-selected card-gradient-border card__one card__item card__action-button",class:{ 'disable-btn': _vm.$store.state.email },on:{"click":function($event){return _vm.ConnectMetamask()}}},[_vm._v(" CONNECT METAMASK "),(_vm.$store.state.connectLoading)?_c('i',{staticClass:"fa fa-circle-o-notch fa-spin loader"}):_vm._e()]):_c('div',{staticClass:"btn-selected card-gradient-border card__one card__item card__action-button address"},[_vm._v(" "+_vm._s(_vm.getAddress().slice(0, 7) + "...." + _vm.getAddress().slice(-4))+" "),(_vm.$store.state.connectLoading)?_c('i',{staticClass:"fa fa-circle-o-notch fa-spin loader"}):_vm._e()]),(
        _vm.hasTaken &&
          _vm.hasTakenResult.message != 'Email is not set' &&
          _vm.hasTakenResult.status == 'success'
      )?_c('div',{staticClass:"card__center card__desc"},[_vm._v(" You have taken your manna version 1 ")]):_vm._e(),(
        _vm.isLinked &&
          _vm.isLinked.status == 'SUCCESSFUL' &&
          _vm.mannaToClaim.value > 0 &&
          (_vm.hasTakenResult.message == 'Email is not set' ||
            _vm.hasTakenResult.value > 0)
      )?_c('div',{staticClass:"btn-selected card-gradient-border card__one card__item card__action-button",on:{"click":function($event){return _vm.claimManna()}}},[_vm._v(" Claim "),(_vm.$store.state.claimLoading)?_c('i',{staticClass:"fa fa-circle-o-notch fa-spin loader"}):_vm._e()]):_vm._e(),(
        _vm.isLinked &&
          _vm.isLinked.status != 'NOT_LINKED' &&
          _vm.isLinked.status != 'SUCCESSFUL'
      )?_c('div',{staticClass:"card__center card__desc"},[(_vm.isLinked.status == 'NOT_VERIFIED')?_c('div',{staticClass:"gradient-border card__barcode card__item",on:{"click":function($event){return _vm.visitLink(_vm.isLinked.link)}}},[_c('qrcode-vue',{attrs:{"value":_vm.isLinked.link,"size":_vm.qrCodeSize,"level":"H"}})],1):_vm._e(),(_vm.isLinked.status == 'TRANSFERRED')?_c('div',{staticClass:"gradient-border card__barcode card__item",on:{"click":function($event){_vm.visitLink(
            'brightid://link-verification/http:%2f%2fnode.brightid.org/idchain/' +
              _vm.getAddress()
          )}}},[_c('qrcode-vue',{attrs:{"value":'brightid://link-verification/http:%2f%2fnode.brightid.org/idchain/' +
              _vm.getAddress(),"size":112,"level":"H"}})],1):_vm._e(),(_vm.isLinked.status == 'TRANSFERRED' && 'NOT_VERIFIED')?_c('div',{staticClass:"btn-selected card-gradient-border card__one card__item card__action-button",class:{ 'disable-btn': _vm.$store.state.alertLoading },on:{"click":function($event){return _vm.checkBrightIDVerification()}}},[_vm._v(" verify connection "),(_vm.$store.state.alertLoading)?_c('i',{staticClass:"fa fa-circle-o-notch fa-spin loader"}):_vm._e()]):_vm._e(),_vm._v(" "+_vm._s(_vm.isLinked.status == "NOT_VERIFIED" ? _vm.isLinked.message : "This BrightID account is linked to " + _vm.isLinked.address + " Please use this address or try to use a new address")+" ")]):_vm._e(),(_vm.isLinked && _vm.isLinked.status == 'NOT_LINKED')?_c('div',{staticClass:"card__center card__desc"},[(_vm.isLinked.status == 'NOT_LINKED')?_c('div',{staticClass:"gradient-border card__barcode card__item",on:{"click":function($event){return _vm.visitLink(_vm.isLinked.link)}}},[_c('qrcode-vue',{attrs:{"value":_vm.isLinked.link,"size":112,"level":"H"},nativeOn:{"click":function($event){return _vm.visitLink(_vm.isLinked.link)}}})],1):_vm._e(),(_vm.isLinked && _vm.isLinked.status == 'NOT_LINKED')?_c('div',{staticClass:"btn-selected card-gradient-border card__one card__item card__action-button",class:{ 'disable-btn': _vm.$store.state.alertLoading },on:{"click":function($event){return _vm.checkBrightIDVerification()}}},[_vm._v(" verify connection "),(_vm.$store.state.alertLoading)?_c('i',{staticClass:"fa fa-circle-o-notch fa-spin loader"}):_vm._e()]):_vm._e(),_vm._v(" Scan this QR-code with your verified BrightID ")]):_vm._e(),(
        _vm.isLinked &&
          _vm.isLinked.status == 'SUCCESSFUL' &&
          // !hasTaken &&
          (_vm.hasTakenResult.message == 'Email is not set' || _vm.hasTakenResult.value > 0)
      )?_c('Divider'):_vm._e(),(
        _vm.isLinked &&
          _vm.isLinked.status == 'SUCCESSFUL' &&
          // !hasTaken &&
          (_vm.hasTakenResult.message == 'Email is not set' ||
            _vm.hasTakenResult.value > 0)
      )?_c('div',{staticClass:"card__center card__desc"},[_vm._v(" "+_vm._s(_vm.isLinked.msg)+" ")]):_vm._e(),(
        _vm.isLinked &&
          _vm.isLinked.status == 'SUCCESSFUL' &&
          (_vm.hasTakenResult.message == 'Email is not set' ||
            _vm.hasTakenResult.value > 0 )
      )?_c('div',{staticClass:"card-gradient-border card__item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"card__input card__one",attrs:{"placeholder":"Enter Mannabase Email...","type":"email","disabled":_vm.$store.state.email},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]):_vm._e(),(
        _vm.isLinked &&
          _vm.isLinked.status == 'SUCCESSFUL' &&
          _vm.$store.state.emailSecret &&
          // !hasTaken &&
          (_vm.hasTakenResult.message == 'Email is not set' ||
            _vm.hasTakenResult.value > 0 )
      )?_c('div',{staticClass:"card-gradient-border card__item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],staticClass:"card__input card__one",attrs:{"placeholder":"Enter 6-digit code from email...","type":"number","disabled":_vm.$store.state.sendCodeMsg == 'SUCCESSFUL'},domProps:{"value":(_vm.code)},on:{"input":function($event){if($event.target.composing)return;_vm.code=$event.target.value}}})]):_vm._e(),(
        _vm.isLinked &&
          _vm.isLinked.status == 'SUCCESSFUL' &&
          _vm.$store.state.email &&
          _vm.$store.state.emailSecret &&
          // !hasTaken &&
          (_vm.hasTakenResult.message == 'Email is not set' ||
            _vm.hasTakenResult.value > 0)
      )?_c('div',{staticClass:"btn-selected card-gradient-border card__one card__item card__action-button",class:{ 'disable-btn': _vm.$store.state.sendCodeMsg == 'SUCCESSFUL' },on:{"click":function($event){return _vm.submitCode()}}},[_vm._v(" SUBMIT "),(_vm.$store.state.submitCodeLoading)?_c('i',{staticClass:"fa fa-circle-o-notch fa-spin loader"}):_vm._e()]):_vm._e(),(
        _vm.isLinked &&
          _vm.isLinked.status == 'SUCCESSFUL' &&
          !_vm.$store.state.email &&
          // !hasTaken &&
          (_vm.hasTakenResult.message == 'Email is not set' ||
            _vm.hasTakenResult.value > 0 ||
            _vm.hasTakenResult.message == 'Email is not set')
      )?_c('div',{staticClass:"btn-selected card-gradient-border card__one card__item card__action-button",on:{"click":function($event){return _vm.sendEmail()}}},[_vm._v(" SEND EMAIL "),(_vm.$store.state.submitEmailLoading)?_c('i',{staticClass:"fa fa-circle-o-notch fa-spin loader"}):_vm._e()]):_vm._e(),(
        _vm.$store.state.sendCodeMsg &&
          // !hasTaken &&
          (_vm.hasTakenResult.message == 'Email is not set' ||
            _vm.hasTakenResult.value > 0)
      )?_c('Divider'):_vm._e(),(
        _vm.$store.state.sendCodeMsg &&
          // !hasTaken &&
          (_vm.hasTakenResult.message == 'Email is not set' ||
            _vm.hasTakenResult.value > 0)
      )?_c('div',[_c('div',{staticClass:"card__center card__desc code-msg",class:{ green: _vm.$store.state.txLink, red: !_vm.$store.state.txLink }},[(_vm.$store.state.sendCodeState == 'success')?_c('h3',{staticClass:"green"},[_vm._v(" Successfully calimed :"+_vm._s(_vm.$store.state.claimed)+" Manna ")]):_vm._e(),(_vm.$store.state.sendCodeState == 'success')?_c('p',{staticClass:"green"},[_vm._v(" Transaction link: ")]):_vm._e(),(_vm.$store.state.sendCodeState == 'success')?_c('a',{staticClass:"green",attrs:{"href":_vm.$store.state.txLink}},[_vm._v(_vm._s(_vm.$store.state.txLink)+" ")]):_vm._e()])]):_vm._e(),(
        _vm.isLinked &&
          _vm.isLinked.status == 'SUCCESSFUL' &&
          _vm.$store.state.email &&
          // !hasTaken &&
          (_vm.hasTakenResult.message == 'Email is not set' ||
            _vm.hasTakenResult.value > 0)
      )?_c('div',[_c('div',{staticClass:"card__link card__link--big card__item",attrs:{"href":""},on:{"click":function($event){return _vm.handleChangeEmail()}}},[_vm._v(" Change Email ")])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }