<template>
  <div>
    <img
      class="card__arrow card__item"
      src="../assets/images/arrow.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  
};
</script>

<style>
</style>