var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showMobileToolbar)?_c('div',{staticClass:"mobile-buttons"},[_c('div',{staticClass:"mobile-buttons__content"},[(!_vm.selectedAddress)?_c('span',{staticClass:"mobile-buttons__content__connect-wallet"},[_c('div',{staticClass:"gradient-border btn btn-back-orange bold-text",on:{"click":function($event){return _vm.ConnectMetamask()}}},[_vm._v(" CONNECT WALLET ")])]):(_vm.$store.state.chainId && _vm.$store.state.chainId != '0x4a')?_c('span',{staticClass:"mobile-buttons__content__connect-wallet"},[_c('div',{staticClass:"gradient-border btn btn-back-red bold-text",on:{"click":function($event){return _vm.changeToIDChain()}}},[_vm._v(" CONNECT IDChain ")])]):_c('span',[(
          _vm.$store.state.contractData.isVerified == false &&
            _vm.isBrightIDVerifiedUnique &&
            _vm.isIDChain
        )?_c('div',{staticClass:"mobile-buttons__content__claim"},[_c('div',{staticClass:"gradient-border btn btn-back-orange",class:{ 'disable-btn': _vm.$store.state.verifyMeLoading },on:{"click":function($event){return _vm.verifyMe()}}},[_vm._v(" Verify "),(_vm.$store.state.verifyMeLoading)?_c('i',{staticClass:"fa fa-circle-o-notch fa-spin loader"}):_vm._e()])]):_vm._e(),(
          _vm.$store.state.contractData.isVerified &&
            _vm.$store.state.contractData.isRegistered == false &&
            _vm.isBrightIDVerifiedUnique &&
            _vm.isIDChain
        )?_c('div',{staticClass:"mobile-buttons__content__claim"},[_c('div',{staticClass:"gradient-border btn btn-back-orange",class:{ 'disable-btn': _vm.$store.state.registerMeLoading },on:{"click":function($event){return _vm.registerMe()}}},[_vm._v(" Register "),(_vm.$store.state.registerMeLoading)?_c('i',{staticClass:"fa fa-circle-o-notch fa-spin loader"}):_vm._e()])]):_vm._e(),(
          _vm.$store.state.contractData.isVerified &&
            _vm.$store.state.contractData.isRegistered &&
            _vm.$store.state.contractData.toClaim &&
            _vm.isIDChain
        )?_c('div',{staticClass:"mobile-buttons__content__claim"},[_c('div',{staticClass:"gradient-border btn btn-back-orange",class:{
            'disable-btn':
              _vm.$store.state.claimLoading ||
              parseInt(_vm.$store.state.contractData.toClaim._hex, 16) == '0',
          },on:{"click":function($event){return _vm.claimManna()}}},[_vm._v(" "+_vm._s("Claim " + parseInt(_vm.$store.state.contractData.toClaim._hex, 16) + " Manna")+" "),(_vm.$store.state.claimLoading)?_c('i',{staticClass:"fa fa-circle-o-notch fa-spin loader"}):_vm._e()])]):_vm._e(),(_vm.isLinked && _vm.mannaBalance)?_c('div',{staticClass:"mobile-buttons__content__manna-amount"},[_c('div',{staticClass:"gradient-border btn btn-back-orange address",on:{"click":function($event){return _vm.addMannaTokenToWallet()}}},[_vm._v(" "+_vm._s(_vm.mannaBalance + " Manna")+" ")])]):_vm._e(),(_vm.isLinked && _vm.guidePage)?_c('div',{staticClass:"mobile-buttons__content__manna-amount"},[_c('div',{staticClass:"gradient-border btn btn-back-orange address",on:{"click":function($event){return _vm.goToMannaOne()}}},[_vm._v(" "+_vm._s("Convert Manna 1.0")+" ")])]):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }